<template>
  <div class="m-p">  
      <DashboardHome />
  </div>
   
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'My Account',
components: {
  DashboardHome: defineAsyncComponent( () => import('@/components/Profile/DashboardHome.vue') ),
},
}
</script>